.em-c-header {
  background: white;
  background-image: none;
}

.em-c-header .em-c-primary-nav__link {
  color: var(--std-semantic-color-fg-tertiary);
}

.em-c-header__body {
  padding: 1rem;
}

.em-c-header__nav-container {
  justify-content: space-between;
}
