.team-card-1 {
  background: var(--std-primitive-color-app-violet);
  color: white;
  min-height: 13rem;
  padding: 1.5rem;
}

.team-card-2 {
  background: #4a3b7d;
  color: white;
  min-height: 13rem;
  padding: 1.5rem;
}

.team-card-3 {
  background: #5a3d7f;
  color: white;
  min-height: 13rem;
  padding: 1.5rem;
}

.team-card-4 {
  background: #6a3f81;
  color: white;
  min-height: 13rem;
  padding: 1.5rem;
}

.team-card-5 {
  background: var(--std-primitive-color-app-purple);
  color: white;
  min-height: 13rem;
  padding: 1.5rem;
}

.team-card-6 {
  background: #7d367b;
  color: white;
  min-height: 13rem;
  padding: 1.5rem;
}

.team-card-1 .em-c-btn--primary,
.em-c-text-passage a.em-c-btn--primary {
  background-color: #252559;
}

.team-card-2 .em-c-btn--primary,
.em-c-text-passage a.em-c-btn--primary {
  background-color: #332957;
}

.team-card-3 .em-c-btn--primary,
.em-c-text-passage a.em-c-btn--primary {
  background-color: #37264d;
}

.team-card-4 .em-c-btn--primary,
.em-c-text-passage a.em-c-btn--primary {
  background-color: #543266;
}

.team-card-5 .em-c-btn--primary,
.em-c-text-passage a.em-c-btn--primary {
  background-color: #632b6b;
}

.team-card-6 .em-c-btn--primary,
.em-c-text-passage a.em-c-btn--primary {
  background-color: #662c64;
}

.team-card-1:hover,
.team-card-2:hover,
.team-card-3:hover,
.team-card-4:hover,
.team-card-5:hover,
.team-card-6:hover {
  background: var(--std-primitive-color-app-red);
  color: white;
}

.team-card-1:hover .em-c-btn--primary,
.team-card-2:hover .em-c-btn--primary,
.team-card-3:hover .em-c-btn--primary,
.team-card-4:hover .em-c-btn--primary,
.team-card-5:hover .em-c-btn--primary,
.team-card-6:hover .em-c-btn--primary {
  background: #990007;
  transition: 0ms;
}

.learn-more-btn {
  flex-direction: row-reverse;
  display: flex;
}
/* --cd: var(--color-app-violet);
--cd-alt: #252559;
--dt: #4a3b7d;
--dt-alt: #332957;
--id: #5a3d7f;
--id-alt: #37264d;
--sd: #6a3f81;
--sd-alt: #543266;
--ur: var(--color-app-purple);
--ur-alt: #632b6b;
--vd: #7d367b;
--vd-alt: #662c64; */
