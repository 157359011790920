body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.em-c-search__input:focus-visible {
  outline-style: none !important;
}

.em-c-select:focus-visible {
  outline-style: none;
  outline-style: none !important;
}

img {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width:100%;
  height:100%;
}

.react-slideshow-container .default-nav {
  color: black !important;
}