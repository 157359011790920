div#root {
  background: #f5f5f5;
}

.em-c-primary-nav__link.em-is-active {
  background: none;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  min-height: calc(100vh - 200px);
}

.em-c-multicolumn-nav {
  min-width:50%
}