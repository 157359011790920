.em-c-modal__window {
  display: block;
  overflow-y: auto;
}

.job-icon {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  border-radius: 50%;
  font-size: 0.85rem;
  color: #fff;
  text-align: center;
  background: #000;
  margin-right: 0.5rem;
}

.em-c-tooltip:not(:has(a)) .em-c-text-passage {
  border-bottom: none;
}

.em-c-section__title {
  font-size: 1.25rem;
  font-weight: 600;
}

.em-c-bulleted-list {
  padding-bottom: 1rem;
}

.em-c-modal--large {
  max-width: 80rem;
}

.em-c-media-block__media {
  display: none;
}

.job-grid {
  display: block;
}

.padding-left {
  padding-left: 2rem;
}

.margin-left {
  margin-left: 0.5rem;
}

.tech-tag .em-c-tags__link {
  background-color: white;
  color: #0c69b0;
  border-style: solid;
  border-width: 1px;
}
