.custom-card .em-c-card {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 30px;
  max-width: 100%;
  border: none;
}

.custom-card .em-c-card:hover {
  transform: scale(1.05);
  transition: transform 0.2s;
}

.custom-card .em-c-stacked-block__img {
  width: 620px;
}
