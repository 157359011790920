.em-c-select {
  max-width: -webkit-fill-available;
}

.sticky-select {
  position: sticky;
  top: 0;
  background-color: #f5f5f5;
  z-index: 999;
}
